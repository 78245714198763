.topo{
	.main-navigation{
		.menu-topo{
			font-family: 'kanitbold';
			font-size: 16px;
			color: #000;

			.dropmenu{
				background-color: #fff;
				list-style: none;
				padding-left: 0;
				margin-bottom: 0;

				li{
					font-family: 'kanitsemibold';
					font-size: 14px;
					margin-top: 5px;
					margin-bottom: 5px;

					&.active{
						a{
							color: #3e3e86;
						}
					}

					&.segura-triangulo{
						position: absolute;
						top: -20px;
						left: 10%;
						filter: drop-shadow( 0px -3px 2px rgba(#000, 0.4));
						
						.triangulo{
							width: 24px;
							height: 15px;
							background-color: #fff;
							clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
						}
					}

					a{
						position: relative;
						padding-left: 20px ;
						transition: all 0.3s linear;

						&:after{
							width: 5px;
							height: 5px;
							background-color: #000;
							content: '';
							position: absolute;
							top: 52%;
							transform: translateY(-50%);
							left: 0;
							border-radius: 50%;
							transition: all 0.3s linear;
						}

						&:hover{
							color: #3e3e86;

							&:after{
								background-color: #3e3e86;
							}
						}
					}
				}
			}

			li{

				&.active{
					a{
						&.main-a{
							color: #7eb85a;
						}
					}
				}
				
				a{
					&:hover{
						color: #7eb85a;
						text-decoration: none;
					}
				}
			}
		}
		.menu-sociais{
			display: flex;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			align-items: center;

			li{
				a{
					width: 50px;
					height: 50px;
					background-color: #ededed;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;

					&:hover{
						text-decoration: none;
						color: rgba(#000, 0.4);
					}
				}

				&+li{
					margin-left: 5px;
				}
			}
		}
	}

	.bg-menu{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.6);
		z-index: 999;
		display: none;
	}
}

// DESKTOP
@include media-breakpoint-only(xl) {

}
// FIM DE DESKTOP



// NETBOOK PRA CIMA
@include media-breakpoint-up(lg) {
	.topo{
		.topo-cima{
			background-color: #3e3e86;
			color: #fff;
			overflow: hidden;

			.container{
				display: flex;
				justify-content: space-between;

				.esq{
					font-family: 'kanititalic';
					display: flex;
					flex-direction: column;
					height: 60px;
					flex-grow: 1;
					text-align: center;
					line-height: 1.2em;

					.esq-conteudo{
						display: flex;
						align-items: center;
						min-height: 40px;
						padding-right: 30px;
					}
					.faixa-branca{
						min-height: 1000px;
						background-color: #fff;
						position: relative;

						&:after{
							position: absolute;
							content: '';
							width: 100vw;
							height: 100%;
							top: 0;
							right: 0;
							background-color: #fff;
						}
					}
				}

				.dir{
					min-width: 760px;
					position: relative;
					z-index: 1;
					padding: 15px 0px 15px 30px;

					&:after{
						position: absolute;
						content: '';
						width: 100vw;
						left: 0;
						background-color: #7eb85a;
						z-index: -1;
						top: 0;
						height: 100%;
					}

					&:before{
						position: absolute;
						content: '';
						background-color: #7eb85a;
						width: 30px;
						height: 100%;
						clip-path: polygon(0 0, 100% 0, 100% 100%);
						top: 0;
						left: -29px;

					}
					.flex-contato{
						display: flex;
						flex-wrap: wrap;
						
						.tel-box{
							margin-right: 30px;
						}
						
						.tel-box,
						.email-box{
							display: flex;
							align-items: center;
							margin-top: 5px;
							margin-left: 5px;

							a{
								&+a{
									padding-left: 10px;
									margin-left: 5px;
									position: relative;

									&:after{
										content: '/';
										position: absolute;
										left: 0;
										top: 50%;
										transform: translateY(-50%);

									}
								}
							}

							span{
								margin-right: 10px;
								margin-left: 5px;
								padding-left: 6px;
								position: relative;

								&:after{
									width: 1px;
									height: 12px;
									top: 50%;
									transform: translateY(-50%);
									background-color: #fff;
									left: 0;
									content: '';
									position: absolute;
								}
							}
						}
					}
				}
			}
		}
		.mbl-controls{
			display: none;
		}
		.main-navigation{
			.tel-box,
			.email-box{
				display: none;
			}
			.container{
				display: flex;

				.logo-topo{
				 	width: 253px;
				 	padding-bottom: 20px;
				}
				.menu-topo{
					display: flex;
					list-style: none;
					padding-left: 0;
					margin-bottom: 0;
					justify-content: space-around;
					align-items: center;
					flex-grow: 1;
					
					li{
						position: relative;
						display: flex;

						a{
							display: flex;

							&.main-a{
								padding: 30px 10px;
							}
						}

						&:hover{
							.dropmenu{
								display: block;
								z-index: 100;
							}
						}
					}

					.dropmenu{
						position: absolute;
						left: 0;
						top: 100%;
						min-width: 350px;
						padding: 20px 10px 10px 30px;
						box-shadow: 0px -2px 5px rgba(#000, 0.4);

						li{
							a{
								margin-top: 10px;
								margin-bottom: 10px;
							}
						}
					}
					.dropmenu-ctrl{
						button{
							display: none;
						}
					}
				}
			}
		}
	}
}
// FIM DE NETBOOK PRA CIMA



// NETBOOK
@include media-breakpoint-only(lg) {
	.topo{
		.topo-cima{
			.container{
				.dir{
					min-width: 600px;
					position: relative;
					z-index: 1;
					padding: 15px 0px 15px 30px;

					&:after{
						position: absolute;
						content: '';
						width: 100vw;
						left: 0;
						background-color: #7eb85a;
						z-index: -1;
						top: 0;
						height: 100%;
					}

					&:before{
						position: absolute;
						content: '';
						background-color: #7eb85a;
						width: 30px;
						height: 100%;
						clip-path: polygon(0 0, 100% 0, 100% 100%);
						top: 0;
						left: -29px;

					}
					.flex-contato{
						display: flex;
						flex-wrap: wrap;

						.tel-box,
						.email-box{
							display: flex;
							align-items: center;
							margin-right: 30px;
							margin-bottom: 5px;

							a{
								&+a{
									padding-left: 10px;
									margin-left: 5px;
									position: relative;

									&:after{
										content: '/';
										position: absolute;
										left: 0;
										top: 50%;
										transform: translateY(-50%);

									}
								}
							}

							span{
								margin-right: 10px;
								margin-left: 5px;
								padding-left: 6px;
								position: relative;

								&:after{
									width: 1px;
									height: 12px;
									top: 50%;
									transform: translateY(-50%);
									background-color: #fff;
									left: 0;
									content: '';
									position: absolute;
								}
							}
						}
					}
				}
			}
		}
	}
}
// FIM DE NETBOOK



// TABLET PRA BAIXO
@include media-breakpoint-down(md) {
	.topo{
		.mbl-controls{
			.container{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0px;

				.logo{
					width: 253px;
				}
				a{
					font-size: 30px;
					padding-right: 20px;
				}
			}
		}
		.topo-cima{
			font-family: 'kanititalic';
			color: #fff;
			background-color: #3e3e86;
			padding: 5px 0px;

			.esq{
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.dir{
				display: none;
			}
		}

		.main-navigation{
			width: 250px;
			height: 100%;
			top: 0;
			left: -250px;
			position: fixed;
			z-index: 1000;
			background-color: #fff;
			padding: 20px 0px;
			overflow-y: auto;
			transition: all 0.4s linear;

			&.active{
				left: 0;
			}

			.container{
				padding: 0px;

				.logo-topo{
					margin-bottom: 20px;
					padding: 10px;
				}
				.menu-topo{
					list-style: none;
					padding-left: 0;

					li{
						

						.dropmenu-ctrl{
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;
							padding-right: 20px;

							button{
								width: 30px;
								height: 30px;
								border-radius: 50%;
								border: none;
								background-color: #3e3e86;
								position: relative;
								transition: all 0.3s linear;

								&[aria-expanded="true"]{
									background-color: rgba(#3e3e86, 0.7);

									&:after{
										content: '\f068'
									}
								}

								&:after{
									font-weight: 900;
									font-family: "Font Awesome 5 Free";
									content: '\f067';
									position: absolute;
									color: #fff;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
							}
						}
						.dropmenu{
							padding-left: 10px;

							li{
								a{
									padding-left: 20px;
								}

								&.segura-triangulo{
									display: none;
								}
							}
						}

						a{
							display: flex;
							padding: 10px;
						}
					}
					
				}
				.menu-sociais{
					justify-content: center;
					margin-top: 20px;
					padding-top: 20px;
					border-top: 2px solid #eaeaea;
					padding-bottom: 20px;
					margin-bottom: 20px;
					border-bottom: 2px solid #eaeaea;
				}
				.tel-box,
				.email-box{
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 10px;

					span{
						font-size: 18px;
					}

					a{
						width: 100%;
						text-align: center;
						overflow-wrap: break-word;
						word-wrap: break-word;
					}
				}
			}
		}

	}
}
// FIM DE TABLET



// TABLET
@include media-breakpoint-only(md) {

}
// FIM DE TABLET



// MOBILE DEITADO
@include media-breakpoint-only(sm) {
	.topo{
		.mbl-controls{
			.container{
				.logo{
					width: 180px;
				}
			}
		}
	}
}
// FIM DE MOBILE DEITADO 



// MOBILE
@include media-breakpoint-down(xs) {
	.topo{
		.mbl-controls{
			.container{
				.logo{
					width: 170px;
				}
			}
		}
	}
}
// FIM DE MOBILE
