.line-clamp {
	max-height: calc(var(--linhas, 2) * 1.5em);
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--linhas, 2);
}

.embed-responsive {
	width: 100%;
	max-width: calc(var(--width, 0) * 1px);
	max-height: calc(var(--height, 0) * 1px);

	&:before{
		padding-bottom: calc((var(--height, 0) / var(--width, 0))  * 100%);
	}
}

lazy-image{
	display: inline-block;
	width: 100%;
	max-width: calc(var(--width, 0) * 1px);
	max-height: calc(var(--height, 0) * 1px);
	overflow: hidden;
	position: relative;
	vertical-align: middle;

	&:before{
		content:'';
		display: block;
		padding-bottom: calc((var(--height, 0) / var(--width, 0)) * 100%);
	}

	&:not(.loaded) {
		background: rgba(#000,.2);

		&:after{
			content: '';
			width: 30px;
			height: 30px;
			border-radius: 50%;
			border:5px solid $primary;
			border-right-color: transparent;
			position: absolute;
			left: calc(50% - 15px);
			top: calc(50% - 15px);
			animation: lazyImage 1s linear infinite;
		}

		img{
			opacity: 0;
		}
	}

	img{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		transition: opacity 0.3s linear;
	}
}

@keyframes lazyImage {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}