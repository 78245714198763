@font-face {
    font-family: 'kanitbold';
    font-display: auto;
    src: url('../fonts/kanit-bold/kanit-bold-webfont.eot');
    src: url('../fonts/kanit-bold/kanit-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-bold/kanit-bold-webfont.woff2') format('woff2'),
         url('../fonts/kanit-bold/kanit-bold-webfont.woff') format('woff'),
         url('../fonts/kanit-bold/kanit-bold-webfont.ttf') format('truetype'),
         url('../fonts/kanit-bold/kanit-bold-webfont.svg#kanitbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'kanitextrabold';
    font-display: auto;
    src: url('../fonts/kanit-extrabold/kanit-extrabold-webfont.eot');
    src: url('../fonts/kanit-extrabold/kanit-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-extrabold/kanit-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/kanit-extrabold/kanit-extrabold-webfont.woff') format('woff'),
         url('../fonts/kanit-extrabold/kanit-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/kanit-extrabold/kanit-extrabold-webfont.svg#kanitextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'kanititalic';
    font-display: auto;
    src: url('../fonts/kanit-italic/kanit-italic-webfont.eot');
    src: url('../fonts/kanit-italic/kanit-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-italic/kanit-italic-webfont.woff2') format('woff2'),
         url('../fonts/kanit-italic/kanit-italic-webfont.woff') format('woff'),
         url('../fonts/kanit-italic/kanit-italic-webfont.ttf') format('truetype'),
         url('../fonts/kanit-italic/kanit-italic-webfont.svg#kanititalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'kanitmedium';
    font-display: auto;
    src: url('../fonts/kanit-medium/kanit-medium-webfont.eot');
    src: url('../fonts/kanit-medium/kanit-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-medium/kanit-medium-webfont.woff2') format('woff2'),
         url('../fonts/kanit-medium/kanit-medium-webfont.woff') format('woff'),
         url('../fonts/kanit-medium/kanit-medium-webfont.ttf') format('truetype'),
         url('../fonts/kanit-medium/kanit-medium-webfont.svg#kanitmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'kanitregular';
    font-display: auto;
    src: url('../fonts/kanit-regular/kanit-regular-webfont.eot');
    src: url('../fonts/kanit-regular/kanit-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-regular/kanit-regular-webfont.woff2') format('woff2'),
         url('../fonts/kanit-regular/kanit-regular-webfont.woff') format('woff'),
         url('../fonts/kanit-regular/kanit-regular-webfont.ttf') format('truetype'),
         url('../fonts/kanit-regular/kanit-regular-webfont.svg#kanitregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'kanitsemibold';
    font-display: auto;
    src: url('../fonts/kanit-semibold/kanit-semibold-webfont.eot');
    src: url('../fonts/kanit-semibold/kanit-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-semibold/kanit-semibold-webfont.woff2') format('woff2'),
         url('../fonts/kanit-semibold/kanit-semibold-webfont.woff') format('woff'),
         url('../fonts/kanit-semibold/kanit-semibold-webfont.ttf') format('truetype'),
         url('../fonts/kanit-semibold/kanit-semibold-webfont.svg#kanitsemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'kanitmediumitalic';
    font-display: auto;
    src: url('../fonts/kanit-mediumitalic/kanit-mediumitalic-webfont.eot');
    src: url('../fonts/kanit-mediumitalic/kanit-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/kanit-mediumitalic/kanit-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/kanit-mediumitalic/kanit-mediumitalic-webfont.woff') format('woff'),
         url('../fonts/kanit-mediumitalic/kanit-mediumitalic-webfont.ttf') format('truetype'),
         url('../fonts/kanit-mediumitalic/kanit-mediumitalic-webfont.svg#kanitmedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}