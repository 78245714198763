.cliente-titulo{
	font-family: 'kanitextrabold';
	font-size: 27px;
	line-height: 31px;
	color: #000;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 60px;

	&:before{
		content: '';
		display: inline-block;
		width: 1em;
		height: .2em;
		background-color: currentColor;
		margin-right: 10px;
	}
}
.conteudo-clientes{
	padding: 60px 0px;
}
.clientes-card{
	margin-bottom: 40px;
	display: block;
	filter: grayscale(100%);
	transition: all 0.3s linear;

	&:hover{
		filter: grayscale(0%);
	}

	.clientes-img{
		text-align: center;
	}
}