#site {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.wrapper{
		flex-grow: 1;
	}
}

[data-animate] {
	opacity: 0;
}

body{
	font-family: 'kanitregular';
	font-size: 14px;
}

a{
	color: inherit;

	&:hover{
		color: inherit;
	}
}

h1, h2, h3, h4, h5, h6, figure{
	margin-bottom: 0;
}

button{
	&:focus{
		outline: none;
	}
}
.header-internas{
	background-color: #e4e4e4;
	font-family: 'kanitmediumitalic';
	font-size: 14px;
	padding: 10px 0px;
	text-align: right;

	a{
		&:hover{
			text-decoration: none;
		}
	}
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  
  100%{
    transform: rotate(359deg);
  }
}

.titulo-interna{
	font-family: 'kanitextrabold';
	font-size: 27px;
	color: #000;
	line-height: 1.2em;
	margin-bottom: 10px;
}
.desc-topo{
	font-family: 'kanitregular';
	font-size: 16px;
	margin-bottom: 60px;
	color: #3f3f3f;
}
.paginacao{
	display: flex;
	justify-content: center;
	margin-top: 50px;
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
	flex-wrap: wrap;

	li{
		display: flex;
		margin: 2px;

		&.active{
			a{
				background-color: #3e3e86;
				color: #fff;
			}
		}

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 33px;
			height: 37px;
			border: 1px solid #3e3e86;
			transition: all 0.3s linear;

			&:hover{
				background-color: #3e3e86;
				text-decoration: none;
				color: #fff;
			}

			&.numero{
				font-size: 16px;
				line-height: 16px;
			}
		}
	}
}
.form-control{
	font-family: 'kanititalic';
	font-size: 14px;
	color: #fff;
	background-color: #262657;
	padding: 10px 15px;
	height: auto;
	border-radius: 0;
	border: 0;

	&:focus{
		outline: none;
		box-shadow: none;
	}

	&::placeholder{
		opacity: 1;
		color: #fff;
	}
}
textarea{
	resize: none;
}