.conteudo-internas{
	padding-bottom: 80px;

	.solucao{
		padding: 60px 0px 40px 0px;

		.conteudog{
			text-align: justify;

			img{
				max-width: 100%;
				height: auto;
			}
		}
	}
	.time-line{
		background-color: #ededed;
		padding-left: 60px;
		padding-top: 40px;
		padding-right: 70px;
		padding-bottom: 40px;

		.card-time{
			padding-bottom: 20px;
			padding-left: 25px;
			position: relative;

			&:first-child{

				&:before{
					content: '';
					width: 2px;
					height: 100%;
					background-color: #7eb85a;
					left: 0;
					top: 20px;
					position: absolute;
				}
			}

			&:last-child{
				padding-bottom: 0px;
			}

			&:before{
				content: '';
				width: 2px;
				height: 100%;
				background-color: #7eb85a;
				left: 0;
				top: 0;
				position: absolute;
			}

			.header-card{
				display: flex;
				align-items: flex-end;


				figure{
					width: 60px;
					margin-right: 10px;
					position: relative;

					&:after{
						content: '';
						position: absolute;
						width: 14px;
						height: 14px;
						top: 50%;
						transform: translateY(-50%);
						left: -25px;
						clip-path: polygon(0 0, 100% 50%, 0 100%);
						background-color: #7eb85a;
					}
				}
				.ano{
					font-family: 'kanitextrabold';
					font-size: 28px;
				}
			}
			.desc-time{
				padding: 5px;
				line-height: 1.3em;
				text-align: justify;
			}
		}
		.botao{
			text-align: center;

			.btn-carregar{
				font-family: 'kanitextrabold';
				font-size: 20px;
				color: #7eb85a;

				&:hover{
					text-decoration: none;
				}
			}
		}
		.loader{
			position: relative;
			width: 100%;
			height: auto;
			display: flex;
			justify-content: center;
			align-items: center;

			&:before{
				content: '';
				margin-top: 20px;
				width: 40px;
				height: 40px;
				border: 5px solid #fff;
				border-radius: 50%;
				border-left-color: #7eb85a;
				animation: loading 1s linear infinite;
				display: block;
				position: relative;
			}
		}
	}
}