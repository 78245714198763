.conteudo-serv-det{
	padding: 60px 0px;

	.barrinha{
		color: #7eb85a;
		font-size: 26px;
		display: inline-block;
		font-family: 'kanitsemibold';
		letter-spacing: -6px;
		margin-right: 10px;
	}
	.detalhes-serv{
		.row{
			.col-lg-5{
				.carousel{
					position: relative;
					.controles{
						display: flex;
						position: absolute;
						bottom: 0;
						right: 0;

						&:before{
							width: 25px;
							content: '';
							position: absolute;
							height: 100%;
							top: 0;
							left: -24px;
							clip-path: polygon(100% 0, 100% 100%, 0 100%);
							background-color: #3e3e86;
						}

						.controle-esq,
						.controle-dir{
							color: #fff;
							background-color: #3e3e86;
							width: 32px;
							height: 50px;
							justify-content: center;
							align-items: center;
							display: flex;
							font-size: 20px;

							&:hover{
								text-decoration: none;
								color: #7eb85a;
								background-color: #2e2e86;
							}
						}
					}
				}
			}
		}
	}
	.recomendacoes{
		padding-top: 40px;

		.titulo-interna{
			margin-bottom: 30px;
		}
		.desc-topo{
			margin-bottom: 20px;
		}
		.recomendacao{
			font-family: 'kanitregular';
			font-size: 16px;
			color: #3f3f3f;
			display: flex;
			align-items: center;

			p{
				margin-bottom: 0;
				margin-left: 20px;
			}
		}
	}
}

// NETBOOK PRA CIMA
@include media-breakpoint-up (lg) {
	.conteudo-serv-det{
		.detalhes-serv{
			.row{
				.col-lg-5{
					order: 1;
				}
				.col-lg-7{
					order: 2;
				}
			}
		}
	}
}
// FIM DE NETBOOK PRA CIMA


// TABLET PRA CIMA
@include media-breakpoint-down (md){
	.conteudo-serv-det{
		.detalhes-serv{
			.carousel{
				max-width: 460px;
				width: 100%;
				height: auto;
				margin-right: auto;
				margin-left: auto;
				margin-top: 20px;
			}
		}
	}
}
// FIM DE TABLET PRA CIMA
