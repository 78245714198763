.rodape{
	.conteudo-rdp{
		background-image: url('../images/rdp-bg.jpg');
		background-position: center top;
		background-size: 1920px 100%;
		background-repeat: no-repeat;
		padding: 50px 0px 30px 0px;
		color: #fff;

		.cima-rodape{
			.col-lg-4{
				.logo-rdp{
					margin-bottom: 20px;
				}
			}
			.col-lg-8{
				.titulo-rdp{
					font-family: 'kanitextrabold';
					font-size: 16px;
					color: #fff;
					margin-bottom: 10px;
				}
				.form-control{
					font-family: 'kanititalic';
					font-size: 14px;
					color: #fff;
					background-color: #262657;
					padding: 10px 15px;
					height: auto;
					border-radius: 0;
					border: 0;

					&:focus{
						outline: none;
						box-shadow: none;
					}

					&::placeholder{
						opacity: 1;
						color: #fff;
					}
				}
				.form-group{
					margin-bottom: 5px;
				}

				.col-lg-6{
					&:nth-child(odd){
						padding-right: 2px;
					}
					&:nth-child(even){
						padding-left: 2px;
					}
				}
				.btn-form{
					width: 100%;
					background-color: #7eb85a;
					padding: 10px 0px;
					border: none;
					font-family: 'kanitbold';
					font-size: 14px;
					color: #fff;
				}

				textarea{
					resize: none;
				}
			}
		} //cima-rodape
		
		.baixo-rodape{
			padding: 20px 0px 0px 0px;
			margin-top: 40px;
			border-top: 1px solid #262657;

			p{
				margin-bottom: 0;
			}

			.rdp-contato{
				display: flex;
				align-items: center;
				
				.inf-contato{
					padding-left: 8px;
					margin-left: 7px;
					position: relative;
					font-family: 'kanitsemibold';
					line-height: 1.1em;

					a{
						&+a{
							position: relative;
							margin-left: 7px;
							padding-left: 11px;
							&:before{
								content: '/';
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
								left: 0px;
							}
						}
					}

					&:before{
						width: 1px;
						height: 14px;
						top: 50%;
						transform: translateY(-50%);
						left: 0;
						content: '';
						position: absolute;
						background-color: #fff;
					}
				}
			}
		}	
	} //conteudo-rdp

	.creditos{
		padding: 20px 0px;

		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;

			p{
				margin-bottom: 0;
			}
			.esq{
				font-size: 11px;

				a{
					display: flex;
					align-items: center;
					margin-top: 8px;

					&:hover{
						text-decoration: none;
					}

					.icon{
						margin-left: 5px;
					}
				}
			}
			.dir{
				.menu-sociais{
					display: flex;
					list-style: none;
					padding-left: 0;
					margin-bottom: 0;

					li{

						a{
							width: 50px;
							height: 50px;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #dbdbdb;
							border-radius: 50%;
							color: #000;

							&:hover{
								text-decoration: none;
								color: rgba(#000, 0.4);
							}
						}
						&+li{
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
}

// TABLET PRA BAIXO
@include media-breakpoint-down (md) {
	.rodape{
		.conteudo-rdp{

			.cima-rodape{
				.col-lg-4{
					margin-bottom: 20px;

					.logo-rdp{
						text-align: center;
					}
				}
				.col-lg-8{
					.titulo-rdp{
						text-align: center;
					}

					.col-lg-6{
						&:nth-child(odd){
							padding-right: 15px;
						}
						&:nth-child(even){
							padding-left: 15px;
						}
					}
				}
			} //cima-rodape
			
			.baixo-rodape{

				.rdp-contato{
					justify-content: center;
					flex-direction: column;
					margin-bottom: 20px;

					.icon{
						margin-bottom: 8px;
					}
					
					.inf-contato{
						padding-left: 0px;
						margin-left: 0px;
						position: relative;
						font-family: 'kanitsemibold';
						line-height: 1.1em;
						display: flex;
						flex-direction: column;
						text-align: center;

						

						a{
							&+a{
								position: relative;
								margin-left: 0px;
								padding-left: 0px;
								&:before{
									display: none;
								}
							}
						}

						&:before{
							display: none;
						}
					}
				}
			}	
		} //conteudo-rdp

		.creditos{
			.container{
				flex-direction: column;
				text-align: center;

				.esq{
					font-size: 11px;
					margin-bottom: 10px;

					a{
						justify-content: center;

						&:hover{
							text-decoration: none;
						}

						.icon{
							margin-left: 5px;
						}
					}
				}
				.dir{
					.menu-sociais{
						display: flex;
						list-style: none;
						padding-left: 0;
						margin-bottom: 0;

						li{

							a{
								width: 36px;
								height: 36px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #dbdbdb;
								border-radius: 50%;
								color: #000;

								&:hover{
									text-decoration: none;
									color: rgba(#000, 0.4);
								}
							}
							&+li{
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}
}
// FIM DE TABLET PRA BAIXO