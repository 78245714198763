.conteudo-empresas{
	padding: 60px 0px;

	.container{
		.titulo-interna{
			margin-bottom: 5px;
			padding-left: 20px;
		}
		.desc-topo{
			font-family: 'kanitregular';
			font-size: 16px;
			margin-bottom: 60px;
		}
		.card-servico{
			.titulo-serv{
				font-family: 'kanitbold';
				font-size: 20px;
				text-align: center;
				margin-top: 10px;
				margin-bottom: 15px;
			}
			.desc{
				text-align: left;
				line-height: 1.4em;
			}
			.botao{
				text-align: left;
				margin-bottom: 30px;

				.btn-conteudo{
					padding: 10px 30px;
				}
			}
		}
	}
}
// MOBILE PRA BAIXO
@include media-breakpoint-down (xs) {
	.conteudo-empresas{
		.container{
			.card-servico{
				text-align: center;
				
				.botao{
					text-align: center;
				}
			}
		}
	}
}
// FIM DE MOBILE PRA BAIXO