.titulo-secao{
	font-family: 'kanitmedium';
	font-size: 18px;
	padding-left: 75px;
	color: #3e3e86;
	position: relative;
	margin-bottom: 10px;

	&:before{
		width: 60px;
		height: 4px;
		background-color: #3e3e86;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		content: '';						}
}
.sub-secao{
	font-family: 'kanitextrabold';
	font-size: 27px;
	line-height: 1.1em;
	max-width: 620px;
	width: 100%;
	margin-bottom: 20px;
}
.desc{
	font-size: 15px;
	color: #3f3f3f;
	text-align: justify;
	line-height: 1.2em;
}
.botao{
	text-align: center;
	padding-top: 30px !important;

	.btn-conteudo{
		font-family: 'kanitbold';
		font-size: 14px;
		color: #fff;
		background-color: #3e3e86;
		padding: 20px 30px 22px 30px;
		border-radius: 50px;

		&:hover{
			text-decoration: none;
		}
	}
	.btn-conteudo-small{
		@extend .btn-conteudo;
		display:inline-block;
		padding:10px 15px;
	}
}
.banner{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.carousel-indicators{
		margin-bottom: 0;

		li{
			height: 60px;
			margin-bottom: 0;
			border: none;
			color: #000;
			text-indent: 0;
			display: flex;
			justify-content: center;
			font-family: 'kanitsemibold';
			font-size: 20px;
			opacity: 1;
			margin-right: 5px;
			margin-left: 5px;
			background-color: rgba(#7eb85a, 0);
			color: #fff;

			&.active{
				background-color: rgba(#7eb85a, 1);
			}
		}
	}

	.carousel-inner{
		width: 160%;
		max-width: 1920px;
		left: 50%;
		transform: translateX(-50%);
	}
}
.historia{
	.time-line{
		.desc{
			--linhas: 3;
		}
	}
}
// DESKTOP
@include media-breakpoint-only(xl) {

}
// FIM DE DESKTOP



// NETBOOK PRA CIMA
@include media-breakpoint-up(lg) {
	.historia{
		padding-top: 60px;
		margin-bottom: -80px;
		z-index: 2;
		position: relative;

		.vrau{
			width: 100%;
			overflow: hidden;
			max-width: 1920px;
			margin-left: auto;
			margin-right: auto;

			.vrau-inner{
				--sangria: calc((100% - 1140px) * 0.5);
				--col: calc(1140px / 12);
				display: grid;
				grid-template-columns: var(--sangria) calc(var(--col) * 8) calc(var(--col) * 4) var(--sangria);
				grid-template-rows: auto auto;
				overflow: hidden;
				width: 160%;
				max-width: 1920px;
				left: 50%;
				transform: translateX(-50%);
				position: relative;

				.historia-div{
					grid-column: 2 / 4;
					grid-row: 1 / 2;
					padding-bottom: 20px;
					padding-right: 30px;
				}
				// .time-line{
				// 	grid-column: 3 / 4;
				// 	grid-row: 1 / 3;
				// 	margin-left: 20px;
				// 	padding-bottom: 130px;

				// 	.card-hist{
				// 		padding-bottom: 40px;
				// 		padding-left: 25px;
				// 		position: relative;
				// 		cursor: default;

				// 		&:hover{
				// 			.ano{
				// 				color: #3e3e86;

				// 				&:before{
				// 					background-color: #3e3e86;
				// 				}
				// 			}
				// 		}

				// 		&:first-child{

				// 			&:before{
				// 				content: '';
				// 				position: absolute;
				// 				width: 2px;
				// 				height: 100%;
				// 				background-color: #7eb85a;
				// 				left: -2px;
				// 				top: 7px;
				// 			}
				// 		}

				// 		&:before{
				// 			content: '';
				// 			position: absolute;
				// 			width: 2px;
				// 			height: 100%;
				// 			background-color: #7eb85a;
				// 			left: -2px;
				// 			top: 0;
				// 		}

				// 		&:last-child{
				// 			padding-bottom: 0;
				// 		}

				// 		.ano{
				// 			font-family: 'kanitextrabold';
				// 			font-size: 27px;
				// 			line-height: 27px;
				// 			position: relative;
				// 			transition: all 0.3 linear;


				// 			&:before{
				// 				content: '';
				// 				position: absolute;
				// 				width: 14px;
				// 				height: 14px;
				// 				top: 50%;
				// 				transform: translateY(-50%);
				// 				left: -25px;
				// 				clip-path: polygon(0 0, 100% 50%, 0 100%);
				// 				background-color: #7eb85a;
				// 				transition: all 0.3 linear;
				// 			}
				// 		}
				// 	}
				// 	.botao{
				// 		margin-top: 60px;
				// 	}
				// }
				.img-historia{
					grid-column: span 2;
				}
			}
		}
	}
	.servicos{
		padding-top: 130px;
		background-color: #7eb85a;
		z-index: 1;
		overflow: hidden;
		position: relative;

		.serv-txt{
			width: 100%;
			max-width: calc((100% / 12) * 6);
			position: relative;
			padding-right: 20px;
			margin-bottom: 60px;

			.sub-secao,
			.desc{
				color: #fff;
			}
		}
		.serv-listagem{
			width: 100%;
			max-width:  calc((100% / 12) * 10);
			position: relative;
			background-color: #fff;
			position: relative;
			z-index: 2;
			padding: 50px 40px 60px 0px;

			&:after{
				content: '';
				background-color: #fff;
				position: absolute;
				width: 100vw;
				height: 100%;
				right: 0;
				top: 0;
				z-index: -1;
			}

			.flex-listagem{
				display: flex;
				justify-content: space-between;

				.conteudo-serv{
					position: relative;
					margin-right: 30px;

					.img-serv{
						width: 448px;
					}

					.desc-serv{
						background-color: rgba(#43438f, 0.87);
						color: #fff;
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: auto;
						padding: 10px 20px 20px 20px;

						.nome-serv{
							font-family: 'kanitbold';
							font-size: 17px;
							margin-bottom: 5px;
						}
						.desc{
							font-family: 'kanitregular';
							font-size: 15px;
							color: #fff;
							line-height: 1.1em;
							text-align: left;
						}
					}
				}
				.nav{
					display: flex;
					flex-direction: column;
					text-align: right;
					font-family: 'kanitsemibold';
					font-size: 15px;
					color: #000;
					border-bottom: none;

					li{
						padding-right: 40px;
						position: relative;
						margin-bottom: 25px;

						a{
							transition: all 0.3 linear;
						}

						&:after{
							content: '';
							position: absolute;
							width: 14px;
							height: 14px;
							top: 50%;
							transform: translateY(-50%);
							right: 10px;
							clip-path: polygon(100% 0, 100% 100%, 0 50%);
							background-color: #7eb85a;
						}

						&:hover{
							a{
								color: #69964c;
								text-decoration: none;

								&.active{
									color: #69964c;
								}
							}
						}
					}
				}
			}
		}
		.img-empresas{
			position: absolute;
			width: 100%;
			max-width: 953px;
			top: 140px;
			left: 50%;
			z-index: 1;
			background-position: center center;
			background-size: cover;
			overflow: hidden;

			&:before{
				--width: 953;
				--height: 680;
				content: '';
				padding-bottom: calc((var(--height)/var(--width)) * 100%);
				display: block;
			}
		}
	}
}
// FIM DE NETBOOK PRA CIMA



// NETBOOK
@include media-breakpoint-only(lg) {
	.historia{
		.vrau{
			.vrau-inner{
				--sangria: calc((100% - 960px) * 0.5);
				--col: calc(960px / 12);
			}
		}
	}
}
// FIM DE NETBOOK



// TABLET PRA BAIXO
@include media-breakpoint-down(md) {
	.historia{
		padding: 40px 0px 0px 0px;

		.vrau{
			.vrau-inner{
				.historia-div{
					margin-bottom: 20px;
				}
				.time-line{
					.card-hist{
						padding-left: 30px;
						margin-bottom: 20px;
						cursor: default;

						&:hover{
							.ano{
								color: #3e3e86;

								&:before{
									background-color: #3e3e86;
								}
							}
						}

						.ano{
							font-family: 'kanitextrabold';
							font-size: 27px;
							line-height: 27px;
							position: relative;
							transition: all 0.3 linear;

							&:before{
								content: '';
								position: absolute;
								width: 14px;
								height: 14px;
								top: 50%;
								transform: translateY(-50%);
								left: -25px;
								clip-path: polygon(0 0, 100% 50%, 0 100%);
								background-color: #7eb85a;
								transition: all 0.3 linear;
							}
						}
					}
					.botao{
						margin-bottom: 60px;
						margin-top: 60px;
					}
				}
			}
		}
	}
	.servicos{
		padding: 50px 0px;

		.container{
			.desc{
				margin-bottom: 30px;
			}

			.serv-listagem{
				.flex-listagem{
					display: flex;
					flex-direction: column;

					.tab-content{
						order: 2;
						text-align: center;

						.conteudo-serv{
							position: relative;
							max-width: 450px;
							height: auto;
							width: 100%;
							text-align: center;
							margin-left: auto;
							margin-right: auto;
							
							.desc-serv{
								background-color: #3e3e86;
								color: #fff;
								position: absolute;
								bottom: 0;
								left: 0;
								width: 100%;
								height: auto;
								padding: 20px;
								
								.nome-serv{
									font-family: 'kanitbold';
									font-size: 17px;
									margin-bottom: 5px;
								}
								.desc{
									font-family: 'kanitregular';
									font-size: 15px;
									color: #fff;
									line-height: 1.1em;
									text-align: center;
									margin-bottom: 0;
								}
							}
						}
					}
					.nav-tabs{
						order: 1;
						border-bottom: none;
						display: flex;
						flex-direction: column;
						font-size: 15px;
						color: #000;
						font-family: 'kanitsemibold';
						margin-bottom: 30px;

						li{
							padding-left: 40px;
							margin-bottom: 15px;
							position: relative;

							a{
								&.active{
									color: #69964c;
								}
								&:hover{
									color: #69964c;
									text-decoration: none;
								}
							}
							
							&:before{
								content: '';
								position: absolute;
								width: 14px;
								height: 14px;
								top: 50%;
								transform: translateY(-50%);
								left: 10px;
								clip-path: polygon(0 0, 100% 50%, 0 100%);
								background-color: #7eb85a;
								transition: all 0.3 linear;
							}
						}
					}
				}
			}
		}
	}
}
// FIM DE TABLET



// TABLET
@include media-breakpoint-only(md) {
	.historia{
		.vrau{
			.vrau-inner{
				.historia-div,
				.time-line{
					max-width: 720px;
					margin-right: auto;
					margin-left: auto;
					padding: 0px 15px;
				}
			}
		}
	}
}
// FIM DE TABLET



// MOBILE DEITADO
@include media-breakpoint-only(sm) {
	.historia{
		.vrau{
			.vrau-inner{
				.historia-div,
				.time-line{
					max-width: 540px;
					margin-right: auto;
					margin-left: auto;
					padding: 0px 15px;
				}
			}
		}
	}
}
// FIM DE MOBILE DEITADO 



// MOBILE
@include media-breakpoint-down(xs) {
	.historia{
		.vrau{
			.historia-div,
			.time-line{
				max-width: 540px;
				margin-right: auto;
				margin-left: auto;
				padding: 0px 15px;
			}
		}
	}
	.servicos{
		.container{
			.serv-listagem{
				.flex-listagem{
					.tab-content{
						.conteudo-serv{
							position: relative;
							max-width: 450px;
							height: auto;
							width: 100%;
							text-align: center;
							margin-left: auto;
							margin-right: auto;
							
							.desc-serv{
								position: relative;
								
								.nome-serv{
									font-family: 'kanitbold';
									font-size: 17px;
									margin-bottom: 5px;
								}
								.desc{
									font-family: 'kanitregular';
									font-size: 15px;
									color: #fff;
									line-height: 1.1em;
									text-align: center;
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
// FIM DE MOBILE