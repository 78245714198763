.conteudo-contatos{
	padding: 60px 0px;


	.form-custom{
		position: relative;
		margin-bottom: 30px;

		.form-control{
			background-color: #fff;
			display: block;
			color: #000;
			background-image: linear-gradient(to right, var(--bgcolor, #7eb85a) 0%, var(--bgcolor, #7eb85a) 33.333%, #3e3e86 33.333%, #3e3e86 100%);
			background-size: 300% 3px;
			background-repeat: no-repeat;
			background-position: center bottom;
			transition: background 0.3s linear;
			outline: none;

			&::placeholder{
				color: transparent;
			}

			&:focus ~ .label-custom,
			&:not(:placeholder-shown) ~ .label-custom{
				top: 0;
				font-size: 12px;
				transform: translateY(-50%);
			}
		}
		.label-custom{
			position: absolute;
			font-family: 'kanititalic';
			font-size: 14px;
			top: 10px;
			left: 15px;
			transition: all 0.3s linear;
		}

		&.has-error{
			--bgcolor: #dc3545;

			.label-custom{
				color: var(--bgcolor);
			}

			.form-control{
				background-position: left bottom;
			}
		}

		&.has-success{
			--bgcolor: #7eb85a;

			.label-custom{
				color: var(--bgcolor);
			}
			.form-control{
				background-position: left bottom;
			}
		}


	}
	.email{
		.numero{
			padding-left: 80px;
			color: #7eb85a;
		}
		.titulo-interna{
			padding-left: 80px;
			position: relative;
			margin-bottom: 40px;
			
			&:before{
				content: '';
				width: 62px;
				height: 6px;
				background-color: #000;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				position: absolute;
			}
		}
		.sub-interna{
			font-family: 'kanitmedium';
			font-size: 20px;
			color: #6f6f6f;
			margin-bottom: 10px;
		}
		.desc-topo{
			color: #000;
			padding-bottom: 40px;
			margin-bottom: 30px;
			border-bottom: 5px solid #000;
		}
		.botao{
			margin-top: 10px;
			margin-bottom: 30px;

			.btn-form{
				width: 100%;
				background-color: #7eb85a;
				border: none;
				color: #fff;
				font-family: 'kanitbold';
				padding: 10px 0px;
			}
		}
		.siganos{
			font-family: 'kanitmedium';
			font-size: 16px;

			a{
				margin-right: 5px;

				&:hover{
					text-decoration: none;
				}
			}
		}
	}
	.contato{
		.numero{
			padding-left: 60px;
			color: #7eb85a;
			margin-top: 20px;
		}
		.titulo-interna{
			font-size: 22px;
			padding-left: 60px;
			position: relative;
			margin-bottom: 40px;

			&:after{
				content: '';
				position: absolute;
				bottom: 10px;
				left: 0;
				width: 40px;
				height: 4px;
				background-color: #000;
			}
		}
		.contato-card{
			display: flex;
			font-size: 16px;
			margin-bottom: 20px;

			.tels,
			.email{
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			p{
				margin-bottom: 0;
			}

			span{
				font-size: 32px;
				width: 40px;
				margin-right: 10px;
				color: #6f6f6f;
			}
		}
	}
}

// TABLET PRA BAIXO
@include media-breakpoint-down (md) { 
	.conteudo-contatos{
		.siganos{
			margin-bottom: 40px;
		}

		.contato{
			figure{
				text-align: center;
			}

			.contato-card{
				flex-direction: column;
				align-items: center;
				text-align: center;

				span{
					margin-bottom: 10px;
				}
			}
		}
	}
}
// FIM DE TABLET PRA BAIXO