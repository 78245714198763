$icons: (
	'arroba': -54px 0px 18px 18px,
	'gv8': 0px 0px 44px 24px,
	'tel': 0px -34px 8px 19px,
);

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 72px 53px;

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}
